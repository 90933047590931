import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CHeroSlider03,
  CDefinition,
  CShopInfo,
  CGrandMenu,
  CShopPrivate,
  CRestaurantPlanRecommend,
  CRestaurantJumbotron,
  CBtnList,
  LContact,
  LContact02,
  LContact03,
  LStores,
  CVariableSlider,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import restaurantPlanChoice from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const shopdata = shopGet('fontaine');

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/fontaine/fontaine_logo.svg',
            alt: '',
            widthSp: '160px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/fontaine/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/fontaine/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-fontaine/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
      />
      <div className="u_pt100 u_mbLarge" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CHeroSlider03
            exClass="slick-aspect-1"
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine02__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine03__sp.png',
                    alt: '',
                  },
                },
              ],
              title: <>クラシカルなラウンジで過ごす優雅なひととき</>,
              text: (
                <>
                  大きな窓から陽光が降りそそぐ開放的な空間には、ソファ席とバーカウンター席。
                  <br />
                  ゆったりとした時間が流れるやわらかな光のなかで、贅沢なティータイムをお過ごしください。
                </>
              ),
            }}
          />
          <CHeroSlider03
            exClass="slick-aspect-1 u_mb100"
            reverse={true}
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine04.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine04__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine05.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine05__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/fontaine/img_fontaine06__sp.png',
                    alt: '',
                  },
                },
              ],
              text: (
                <>
                  パティシエの感性と技が光る繊細なチョコレート細工が特徴のアフタヌーンティー。
                  <br />
                  コーヒーや紅茶をはじめ、お好みのドリンクとともに会話に華を添えるスイーツや軽食をお楽しみいただけます。
                </>
              ),
            }}
          />
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: '店舗情報',
            }}
          />
          {/* <p className="u_tac_sp">
            最新の営業時間は、<a href="/information/timeindex/">こちら</a>
            をご覧ください。
          </p> */}
          <CShopInfo data={shopdata} exClass="u_mt20" />
        </LWrap>
      </div>
      <section className="l_sect u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'MENU',
              ja: 'おすすめメニュー',
            }}
          />
          <CRestaurantPlanRecommend
            data={restaurantPlanChoice(
              restaurantPlanGet(),
              ['ロビーラウンジ フォンテーヌ'],
              0
            )}
          />
          <CBtnList
            data={[
              {
                label: '他のプランを見る',
                link: {
                  href: '/restaurants/plan/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
          <CGrandMenu data={shopdata} exClass="u_mt70 u_mb20" />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-fontaine/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
